import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';


export class RecordNoteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onRecordNoteChanged = this.onRecordNoteChanged.bind(this);
    this.onRecordNoteActionChanged = this.onRecordNoteActionChanged.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
  }

  componentDidMount() {
    this.fetchDetails();
  }

  fetchDetails() {
    this.setState({loading: true});
    let {record_note_id} = this.props;
    let url = '/admin/record_notes/' + record_note_id;
    request('GET', url).then(record_note => {
      this.setState({record_note, loading: false});
    });
  }

  archiveNote() {
    if (!confirm('Are you sure?')) return;
    let {record_note_id, onClose} = this.props;
    let url = `/admin/record_notes/${record_note_id}/archive`;
    request('PUT', url).then(() => {
      onClose()
    });
  }

  unarchiveNote() {
    if (!confirm('Are you sure?')) return;
    let {record_note_id, onClose} = this.props;
    let url = `/admin/record_notes/${record_note_id}/unarchive`;
    request('PUT', url).then(() => {
      onClose()
    });
  }

  deleteAction(record_note_action) {
    if (!confirm('Are you sure?')) return;
    let {record_note_id, onClose} = this.props;
    let url = `/admin/record_notes/${record_note_id}/record_note_actions/${record_note_action.id}`;
    request('DELETE', url).then(() => {
      let {record_note} = this.state;
      let index = record_note.actions.findIndex(a => a.id === record_note_action.id);
      record_note.actions.splice(index, 1);
      this.setState({record_note});
    });
  }

  onRecordNoteChanged(record_note) {
    this.setState({record_note, mode: 'details'});
  }

  onRecordNoteActionChanged(record_note_action) {
    let {record_note} = this.state;
    let index = record_note.actions.findIndex(a => a.id === record_note_action.id);
    if (index === -1) {
      record_note.actions.push(record_note_action);
    } else {
      record_note.actions[index] = record_note_action;
    }

    this.setState({record_note, mode: 'details'});
  }

  onCancelEdit() {
    this.setState({ mode: 'details'});
  }

  editRecordNote() {
    this.setState({mode: 'record_note_form'});
  }
  createAction() {
    let record_note_action = {};
    this.setState({mode: 'action_form', record_note_action});
  }

  editAction(record_note_action) {
    this.setState({mode: 'action_form', record_note_action});
  }

  renderRecordNoteForm() {
    let {record_note} = this.state;
    return <RecordNoteForm record_note={record_note} onSubmit={this.onRecordNoteChanged} onCancel={this.onCancelEdit} />
  }

  renderActionForm() {
    let {record_note_action} = this.state;
    let {record_note_id} = this.props;
    return <RecordNoteActionForm record_note_id={record_note_id} record_note_action={record_note_action} onSubmit={this.onRecordNoteActionChanged} onCancel={this.onCancelEdit} />
  }

  renderArchiveButton() {
    let {record_note} = this.state;
    if (record_note.archived) {
      return <button className="btn btn-warning" onClick={e => this.unarchiveNote()}><i className="fa fa-check"></i> Revert Resolved</button>
    } else {
      return <button className="btn btn-success" onClick={e => this.archiveNote()}><i className="fa fa-check"></i> Mark as Resolved</button>
    }
  }

  renderDetails() {
    let {record_note} = this.state;
    let {onClose} = this.props;
    let record_date = moment(record_note.created_at).format('LLL');
    return (
      <div>
        <div className="record_note_show_header_btns">
          <button className="btn btn-default btn-default" onClick={onClose}>See All Curation Notes</button>
          {this.renderArchiveButton()}
        </div>
        <div className="record_note_show_holder">
          <p>
            <b>Curation Note from {record_note.admin_name}</b>
            <span className="subtle"> - {record_date}</span>
          </p>
          <Markdown text={record_note.description} />
          <button className="btn btn-default btn-xs" onClick={e => this.editRecordNote()}>Edit Note</button>
        </div>
        <div className="record_note_comment_list">
          <p><b>COMMENTS</b></p>
          {record_note.actions.map(action => {
            let date = moment(action.created_at).format('LLL');
            return (
              <div key={action.id} className="record_note_comment">
                <Markdown text={action.description} />
                <p>&mdash; {action.admin_name} <span className="subtle">on {date}</span></p>
                <div className="btn-group">
                  <button className="btn btn-default btn-xs" onClick={e => this.editAction(action)}>Edit Comment</button>
                  <button className="btn btn-default btn-xs" onClick={e => this.deleteAction(action)}>Delete Comment</button>
                </div>
              </div>
            )
          })}
        </div>
        <button className="btn btn-default btn-default" onClick={e => this.createAction()}>Add Comment</button>
      </div>
    )
  }

  renderContent() {
    let {mode} = this.state;
    if (mode === 'record_note_form') {
      return this.renderRecordNoteForm();
    } else if (mode === 'action_form') {
      return this.renderActionForm();
    } else {
      return this.renderDetails();
    }
  }
  render() {
    let {loading, record_note} = this.state;
    if (loading || !record_note) return <Loading />

    return this.renderContent();
  }
}

export class RecordNoteForm extends Component {
  constructor(props) {
    super(props);
    let {record_note} = props;
    this.state = {record_note};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    let {record_note} = this.state;
    let {onSubmit} = this.props;
    let url = '/admin/record_notes';

    let method;
    if (record_note.id) {
      url += '/' + record_note.id;
      method = 'PUT';
    } else {
      method = 'POST';
    }

    request(method, url, {record_note}).then(record_note => {
      onSubmit(record_note);
    });
  }

  onChange(record_note) {
    this.setState({record_note});
  }

  render() {
    let {record_note} = this.state;
    let {onCancel} = this.props;
    let fields = [
      {name: 'description', label: 'Your Note', type: 'text', required: true, autosize: true}
    ];

    return <Form model={record_note} model_name="Curator Note" fields={fields} onChange={this.onChange} onSubmit={this.submit} onCancel={onCancel} />
  }
}


export class RecordNoteActionForm extends Component {
  constructor(props) {
    super(props);
    let record_note_action = props.record_note_action || {};
    this.state = {record_note_action};
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  submit() {
    let {record_note_action} = this.state;
    let {record_note_id, onSubmit} = this.props;
    let url = `/admin/record_notes/${record_note_id}/record_note_actions`;

    let method;
    if (record_note_action.id) {
      url += '/' + record_note_action.id;
      method = 'PUT';
    } else {
      method = 'POST';
    }

    request(method, url, {record_note_action}).then(record_note_action => {
      onSubmit(record_note_action);
    });
  }

  onChange(record_note_action) {
    this.setState({record_note_action});
  }

  render() {
    let {record_note_action} = this.state;
    let {onCancel} = this.props;
    let fields = [
      {name: 'description', label: 'Comment', type: 'text', required: true, autosize: true}
    ];

    return <Form model={record_note_action} model_name="Comment" fields={fields} onChange={this.onChange} onSubmit={this.submit} onCancel={onCancel} />
  }

}


export class RecordNoteStandalone extends Component {
  back() {
    let {back_location} = this.props;
    window.location = back_location;
  }

  render() {
    let {record_note_id} = this.props;
    return <RecordNoteDetails record_note_id={record_note_id} onClose={e => this.back()} />
  }
}
