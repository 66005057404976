import React, {Component} from 'react';
import Form from './form';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';
import {PopulationRubricTypes} from '../constants.js.erb';

const TABS = [
  {id: 'openai', label: 'OpenAI'},
  {id: 'mistral', label: 'Mistral', filter: state => !!state.mistral_workflow_data}
]

const SECTIONS = [
  {id: 'scorability', name: 'Scorability'},
  {id: 'efficacy_rubric', name: 'Rubric Type'},
  {id: 'rubric_content', name: 'Rubric', content: d => d[d.efficacy_rubric_key]},
  {id: 'safety', name: 'Safety'}
]

const PARTIAL_SECTIONS = [
  {id: 'scorability', name: 'Scorability'},
]

export default class ReferenceWorkflowData extends Component {
  constructor(props) {
    super(props);
    this.state = {current_tab: 'openai'};
    this.onChangeOverwriteForm = this.onChangeOverwriteForm.bind(this);
    this.onSubmitOverwriteForm = this.onSubmitOverwriteForm.bind(this);
 }

  componentDidMount() {
    this.fetchWorkflowData();
  }

  fetchWorkflowData(sources=false) {
    let {reference_id} = this.props;
    if (sources === false) {
      sources = ['openai', 'mistral']
    }

    let url = `/admin/references/${reference_id}/workflow_data?sources=${sources.join(',')}`;
    request('GET', url).then(({text_summary, data}) => {
      let state_changes = {text_summary, loading: false};
      let pending_sources = [];
      for (let source of data) {
        if (source.data && source.data.data_requested_at) {
          pending_sources.push(source.id)
        }
        state_changes[source.key] = source.data;
      }

      if (pending_sources.length > 0) {
        setTimeout(e => this.fetchWorkflowData(pending_sources), 3000);
      }

      this.setState(state_changes);
    }).catch(error => {
      this.setState({loading: false});
    });
  }

  requestWorkflowData(id, overwrite_data=null) {
    let key = id + '_workflow_data';
    this.setState({[key]: {data_requested_at: true}});

    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/request_workflow_data`;
    return request('POST', url, {source: id, overwrite_data}).then((workflow_data) => {
      setTimeout(e => this.fetchWorkflowData([id]), 3000);
    }).catch(error => {
      this.setState({[key]: null});
    });
  }

  onChangeOverwriteForm(overwrite_data) {
    this.setState({overwrite_data});
  }

  onSubmitOverwriteForm() {
    let {show_overwrite_form, overwrite_data} = this.state;
    this.setState({loading: true, show_overwrite_form: null});
    this.requestWorkflowData(show_overwrite_form, overwrite_data).then(e => {
      this.setState({overwrite_form: null});
    });
  }

  selectTab(e, current_tab) {
    e.preventDefault();
    this.setState({current_tab});
  }

  showOverwriteForm(id) {
    this.setState({show_overwrite_form: id, overwrite_data: {}}, e => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  renderNoAbstractText() {
    let {reference_id} = this.props;
    let url = `/admin/references/${reference_id}/edit`;
    return (
      <div>
        <strong>No abstract text set</strong>
        <p>Set abstract text to generate the summary</p>
        <a href={url} target="_blank">Edit Reference</a>
      </div>
    );
  }

  renderRequestWorkflowData(id) {
    return (
      <div>
        <p>Workflow Data not found.</p>
        <button className="btn btn-success" onClick={e => this.requestWorkflowData(id)}>Request Workflow Data</button>
      </div>
    )
  }


  renderPartialData(data, id) {
    let update_btn, edit_btn, date_info;

    if (id) {
      update_btn = <button className="btn btn-success" onClick={e => this.requestWorkflowData(id)}>Update Workflow Data</button>
    }

    if (data.updated_at) {
      let updated_at = moment(data.updated_at).format('LLL');
      date_info = <p><i>Data generated on {updated_at}</i></p>;
    }
    return (
      <div className="ai_output_section">
        <h3>Partial Data</h3>
        {PARTIAL_SECTIONS.map(section => {
          let content = section.content ? section.content(data) : data[section.id];
          if (!content) return null;
          return (
            <div key={section.id} className={`output-section-holder ${section.id}`}>
              <h2 className="ai_output_section_header">{section.name} Results:</h2>
              <Markdown text={content} />
            </div>
          )

        })}
        {date_info}
        <div className="btn-group">
          {update_btn}
        </div>
      </div>
    )
  }

  renderSections(data, id) {
    if (data === null) {
      return this.renderRequestWorkflowData(id);
    }

    if (data.data_requested_at) {
      return <Loading />
    }

    if (data.partial_data) {
      return this.renderPartialData(data, id);
    }

    let update_btn, edit_btn;

    if (id) {
      update_btn = <button className="btn btn-success" onClick={e => this.requestWorkflowData(id)}>Update Workflow Data</button>
      edit_btn = <button className="btn btn-default" onClick={e => this.showOverwriteForm(id)}>Overwrite Details</button>
    }

    let updated_at = moment(data.updated_at).format('LLL');
    return (
      <div className="ai_output_section">
        {SECTIONS.map(section => {
          let content = section.content ? section.content(data) : data[section.id];
          if (!content) return null;
          return (
            <div key={section.id} className={`output-section-holder ${section.id}`}>
              <h2 className="ai_output_section_header">{section.name} Results:</h2>
              <Markdown text={content} />
            </div>
          )

        })}
        <p><i>Data generated on {updated_at}</i></p>
        <div className="btn-group">
          {update_btn}
          {edit_btn}
        </div>
      </div>
    )
  }

  renderOpenai() {
    let {openai_workflow_data} = this.state;
    return this.renderSections(openai_workflow_data, 'openai');
  }

  renderMistral() {
    let {mistral_workflow_data} = this.state;
    return this.renderSections(mistral_workflow_data);
  }

  renderContent() {
    let {current_tab, loading, show_overwrite_form} = this.state;

    if (show_overwrite_form) {
      return this.renderForm();
    }  else if (current_tab === 'mistral') {
      return this.renderMistral();
    } else {
      return this.renderOpenai();
    }
  }

  renderForm() {
    let {overwrite_data} = this.state;
    let fields = [
      {name: 'scorability_overwrite', type: 'select', collection: ['Scorable', 'Notable', 'Not Scorable or Notable']},
      {name: 'efficacy_rubric_key_overwrite', type: 'select', collection: PopulationRubricTypes}
    ]

    return (
      <div className="overwrite_form_box">
        <Form fields={fields} model={overwrite_data} onChange={this.onChangeOverwriteForm} onSubmit={this.onSubmitOverwriteForm} onCancel={e => this.setState({show_overwrite_form: null})} submitLabel="Overwrite"/>
      </div>
    )
  }


  renderTabs() {
    if (window.location.hash !== '#show_other_sources') return null;

    let {current_tab, mistral_workflow_data} = this.state;
    if (mistral_workflow_data === null) return null;

    let tabs =TABS.map(tab => {
      if (tab.filter && tab.filter(this.state) === false) return null;

      let active = tab.id === current_tab;
      let className = active ? 'active' : '';
      return <li key={tab.id} role="presentation" className={className}><a href="#" onClick={e => this.selectTab(e, tab.id)}>{tab.label}</a></li>
    });

    return (
      <ul className="nav nav-tabs">
        {tabs}
      </ul>
    )
  }

  render() {
    let {loading, text_summary} = this.state;
    if (loading) return <Loading />
    if (!text_summary || text_summary.length === 0) return this.renderNoAbstractText()
    return (
      <div>
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    )
  }

}
