/**
 * Tools for add url navigation to tabs
 * Used on the trial_details partial to show logistics and medical
 * tabs
 */
export default {
  setup: function() {
    window.addEventListener('load', function () {
      setupLinks();
      setupTabs();
    });
  }
}

function setupLinks() {
  let elements = document.querySelectorAll('[data-anchor]');
  for (let el of elements) {
    let anchor = el.getAttribute('data-anchor');
    el.addEventListener('click', () => {
      let url = window.location.pathname + '#' + anchor;
      window.history.pushState({}, null, url);
    });
  }
}


function setupTabs() {
  let hash = window.location.hash;
  if (!hash || hash.length === 0) return;
  let anchor = hash.replace('#', '');

  let elements = document.querySelectorAll('[data-active-tab-from-url]');
  for (let el of elements) {
    let link = el.querySelector(`[data-anchor="${anchor}"] a`);
    link.click();
  }
}
