import React, {Component} from 'react';
import {RecordNoteDetails, RecordNoteForm} from './record_note';
import Loading from './loading';
import Markdown from './markdown';
import request from '../request';

export default class RecordNotesWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showModal = this.showModal.bind(this);
  }

  showModal() {
    this.setState({show_modal: true}, () => {
      $(this.refs.record_notes_modal).modal({show: true});
      $(this.refs.record_notes_modal).on('hide.bs.modal', () => {
        this.setState({show_modal: false});
      });
    });
  }

  renderModal() {
    let {show_modal} = this.state;
    if (!show_modal) return false;
    let {record_type, record_id} = this.props;

    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="record_notes_modal" ref="record_notes_modal">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 className="modal-title">Curation Notes for this {record_type}</h4>
            </div>
            <div className="modal-body">
              <RecordNotesList record_type={record_type} record_id={record_id} />
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let {buttonClassName} = this.props;
    if (!buttonClassName || buttonClassName.length === 0) {
      buttonClassName = "btn btn-success";
    }
    return (
      <div>
        {this.renderModal()}
        <button className={buttonClassName} onClick={this.showModal}>
          <i className="fa fa-pencil"></i> Curation Notes
        </button>
      </div>
    );
  }
}



class RecordNotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onRecordNoteSubmitted = this.onRecordNoteSubmitted.bind(this);
    this.closeDetails = this.closeDetails.bind(this);
  }

  componentDidMount() {
    this.fetchNotes();
  }

  fetchNotes() {
    this.setState({loading: true});
    let {record_type, record_id} = this.props;
    let url = `/admin/record_notes/for_record?record_type=${record_type}&record_id=${record_id}`
    request('GET', url).then(record_notes => {
      let changes = {record_notes, loading: false};
      this.setState(changes);
    })
  }

  onRecordNoteSubmitted(record_note) {
    let {record_notes} = this.state;
    record_notes.push(record_note);
    this.setState({record_notes, mode: 'list'});
  }

  addRecordNote() {
    this.setState({mode: 'form'});
  }

  showDetails(record_note) {
    this.setState({record_note, mode: 'details'});
  }

  closeDetails() {
    this.fetchNotes();
    this.setState({mode: 'list'});
  }

  toggleArchived() {
    let show_archived = !this.state.show_archived;
    this.setState({show_archived});
  }

  renderNoNotes() {
    return (
      <div>
        <p>No notes found for this record.</p>
        <button className="btn btn-default btn-success" onClick={e => this.addRecordNote()}><i className="fa fa-plus"></i> Add Note</button>
      </div>
    )
  }


  renderNotesList(record_notes) {
    return record_notes.map(record_note => {
      let date = moment(record_note.created_at).format('LLL');
      return (
        <div key={record_note.id} className="record_note">
          <p><b>Note from {record_note.admin_name} on {date}</b></p>
          <Markdown text={record_note.description} />
          <button className="btn btn-default btn-sm" onClick={e => this.showDetails(record_note)}>View - {record_note.record_note_actions_count} Responses</button>
        </div>
      )
    });
  }


  renderArchivedNotesButton(archived_notes) {
    if (archived_notes.length === 0) return null;
    let {show_archived} = this.state;
    let archived_button_label = show_archived ? 'Hide Resolved Notes' : `Show ${archived_notes.length} Resolved Notes`;
    return <button className="btn btn-default" onClick={e => this.toggleArchived()}>{archived_button_label}</button>
  }

  renderArchivedNotes(archived_notes) {
    let {show_archived} = this.state;
    if (!show_archived)return null;

    return (
      <div className="record_notes_list archived_notes_list">
        <p><b>RESOLVED</b></p>
        {this.renderNotesList(archived_notes)}
      </div>
    )
  }


  renderNotes() {
    let {record_notes, loading} = this.state;
    if (loading) return <Loading />
    if (!record_notes || record_notes.length === 0) return this.renderNoNotes()

    let active_notes = record_notes.filter(r => !r.archived);
    let archived_notes = record_notes.filter(r => r.archived);

    return (
      <div id="record_notes">
        <button className="btn btn-default btn-sm btn-success" onClick={e => this.addRecordNote()}><i className="fa fa-plus"></i> Add Note</button>
        <div className="record_notes_list">
          {this.renderNotesList(active_notes)}
        </div>

        {this.renderArchivedNotesButton(archived_notes)}
        {this.renderArchivedNotes(archived_notes)}
      </div>
    )
  }

  render() {
    let {mode, record_note} = this.state;
    if (mode === 'details') {
      return <RecordNoteDetails record_note_id={record_note.id} onClose={this.closeDetails} />
    } else if(mode === 'form') {
      let {record_type, record_id} = this.props;
      let record_note = {record_type, record_id};
      return <RecordNoteForm record_note={record_note} onSubmit={this.onRecordNoteSubmitted} onCancel={this.closeDetails} />
    } else {
      return this.renderNotes();
    }
  }
}
