import React, {Component} from 'react';
import request from '../request';
import Form from './form';
import {getLocationLabel, locationsComparator} from './locations';
import {calculateDistance} from './geolocation';
import AdminsCache from './admins_cache';
import TrialCollectionStarsRating from './trial_collection_stars_rating';
import {titleize} from 'inflected';
import {TrialLocationAvailabilityOptions, TrialLocationSourceOfVerificationOptions, RationaleStatuses} from '../constants.js.erb';

export default class PatientTrialData extends Component {
  constructor(props) {
    super(props);
    let {therapy_type, trial_data, trial_locations, cancer_centers, people} = props;

    // Clean up deleted references
    if (therapy_type === 'trial') {
      trial_data.trial_location_ids = trial_data.trial_location_ids.filter(trial_data_location_id => trial_locations.findIndex(tl => tl.id === trial_data_location_id) != -1);
      trial_locations = trial_locations.sort(locationsComparator);
    } else {
      trial_data.cancer_center_ids = trial_data.cancer_center_ids.filter(trial_data_location_id => cancer_centers.findIndex(tl => tl.id === trial_data_location_id) != -1);
    }

    this.state = {trial_data, trial_locations, people, changed: false, show_save_feedback: false};

    this.changeField = this.changeField.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let {therapy_type, trial_data, trial_locations, people} = props;
    if (therapy_type === 'trial') {
      state.trial_locations = trial_locations.sort(locationsComparator);
    }
    state.trial_data = trial_data;
    state.people = people;
    return state;
  }

  submit(e) {
    e.preventDefault();
    let {trial_id, patient} = this.props;
    let {trial_data} = this.state;

    let url = "/admin/patients/" + patient.id + "/trials/" + trial_id;
    request('PUT', url, {trial_data}).then(resp => {
      this.setState({changed: false, show_save_feedback: true});
      if (this.feedback_pid) clearTimeout(this.feedback_pid);
      this.feedback_pid = setTimeout(() => this.setState({show_save_feedback: false}), 5000);
    });
  }


  addPerson(person) {
    let {trial_data, people} = this.state;
    trial_data.person_ids.push(person.id);
    people.push(person);
    this.setState({trial_data, people, changed: true});
  }

  deletePerson(person_id) {
    let {trial_data} = this.state;
    let index = trial_data.person_ids.findIndex(id => id === person_id);
    if (index === -1) return;
    trial_data.person_ids.splice(index, 1);

    if (trial_data.primary_person_id === person_id) {
      trial_data.primary_person_id = null;
    }

    this.setState({trial_data, changed: true});
  }


  addStatus(field, status) {
    let {current_admin_id} = this.props;
    let {trial_data} = this.state;

    status.admin_id = current_admin_id;
    status.created_at = new Date();

    trial_data[field].push(status);
    this.setState({trial_data, changed: true});
  }

  changeField(field, value) {
    let {trial_data} = this.state;
    trial_data[field] = value;
    this.setState({trial_data, changed: true});
  }

  change(e) {
    let {trial_data} = this.state;
    let {name, value} = e.target;
    trial_data[name] = value;
    this.setState({trial_data, changed: true});
  }


  renderRationaleStatus() {
    let {rationale_status} = this.state.trial_data;
    if (rationale_status.length === 0) {
      return <strong>Unknown</strong>;
    }

    let current = rationale_status[rationale_status.length - 1];
    return (
      <div>
        <strong>{current.status}</strong>
        <button className="btn btn-sm btn-secondary" onClick={e => this.setState({show_rationale_form: true})}>
          Update Rationale Status
        </button>
      </div>
    )
  }

  renderSubmit() {
    let {changed, show_save_feedback} = this.state;

    let btnClass, label = "";
    if (changed) {
      btnClass = "btn btn-sm btn-primary"
      label = <span>You must save all changes.</span>
    } else {
      btnClass = "btn btn-sm btn-default"
      if (show_save_feedback) label = <span>Saved successfully.</span>
    }

    return (
      <div className="section save-section first-save-section">
        <button onClick={e => this.submit(e)} className={btnClass} >Update</button>
        {label}
      </div>
    )
  }

  renderLocationsOrCancerCenters() {
    let {therapy_type} = this.props;
    if (therapy_type === 'trial') {
      return this.renderLocations();
    } else {
      return this.renderCancerCenters();
    }
  }

  renderLocations() {
    let {trial_groups, trial_id, patient} = this.props;
    let {trial_data, trial_locations} = this.state;
    return (
      <div className="section location-section">
        <h3>Relevant Locations</h3>
        <Locations patient={patient} location_ids={trial_data.trial_location_ids} primary_location_id={trial_data.primary_trial_location_id} trial_locations={trial_locations} onChange={this.changeField} />
        </div>

    );
  }

  renderCancerCenters() {
    let {trial_groups, trial_id, patient, cancer_centers} = this.props;
    let {trial_data} = this.state;
    return (
      <div className="section location-section">
        <h3>Relevant Cancer Centers</h3>
        <CancerCenters patient={patient} center_ids={trial_data.cancer_center_ids}  primary_cancer_center_id={trial_data.primary_cancer_center_id} cancer_centers={cancer_centers} onChange={this.changeField} />
      </div>

    );
  }

  renderEfficacyRatings() {
    let {trial_data} = this.state;

    let fields = [
      {name: 'efficacy_monotherapy_rating', label: 'Efficacy (monotherapy)', type: 'stars-rating', extraOptions: TrialCollectionStarsRating.EXTRA_OPTIONS},
      {name: 'efficacy_combination_rating', label: 'Efficacy (combination)', type: 'stars-rating', extraOptions: TrialCollectionStarsRating.EXTRA_OPTIONS}
    ]

    return (
      <Form model={trial_data} fields={fields} onChangeField={this.changeField} containerElement='div' />
    )
  }

  renderSafetyRatings() {
    let {trial_data} = this.state;

    let fields = [
      {name: 'safety_monotherapy_rating', label: 'Safety (monotherapy)', type: 'stars-rating', extraOptions: TrialCollectionStarsRating.EXTRA_OPTIONS},
      {name: 'safety_combination_rating', label: 'Safety (combination)', type: 'stars-rating', extraOptions: TrialCollectionStarsRating.EXTRA_OPTIONS}
    ]

    return (
      <Form model={trial_data} fields={fields} onChangeField={this.changeField} containerElement='div' />
    )
  }

  render() {
    let {trial_data, people} = this.state;
    let {trial_groups, trial_id, patient} = this.props;
    let link_to_in_collections = '/admin/trials/' + trial_id + '/in_collections'

    return (
      <div>
        {this.renderSubmit()}

        <div className="section status-section">
          <h3>Rationale Status</h3>
          <RationaleStatus status_records={trial_data.rationale_status} onNewStatus={s => this.addStatus('rationale_status', s)}  />
        </div>

        {this.renderLocationsOrCancerCenters()}

        <div className="section contacts-section">
          <h3>Relevant Contacts</h3>
          <Contacts trial_id={trial_id} patient_id={patient.id} people={people} person_ids={trial_data.person_ids} onAddPerson={p => this.addPerson(p)} onDeletePerson={p => this.deletePerson(p)} primary_person_id={trial_data.primary_person_id} onChange={this.changeField} />
        </div>

        <div className="section group-section">
          <h3>Selected Group</h3>
          <GroupPicker group_id={trial_data.group_id} groups={trial_groups} onChange={id => this.changeField('group_id', id)} />
        </div>

        <div>



          <div className="section notes-section">
            {this.renderEfficacyRatings()}

            <div className="form-group">
              <label>Efficacy Notes (Patient Specific)</label>
              <textarea className="form-control" rows="3" name="efficacy_notes" value={trial_data.efficacy_notes || ''} onChange={e => this.change(e)}></textarea>
            </div>

            {this.renderSafetyRatings()}

            <div className="form-group">
              <label>Side Effects Notes (Patient Specific)</label>
              <textarea className="form-control" rows="3" name="side_effects_notes" value={trial_data.side_effects_notes || ''} onChange={e => this.change(e)}></textarea>
            </div>

            <a href={link_to_in_collections}>See Notes in Other Collections</a>
          </div>

          {this.renderSubmit()}
        </div>

      </div>
    )
  }
}


class AbstractStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {admins: []}
  }

  componentDidMount() {
    AdminsCache.get().then(admins => {
      this.setState({admins});
    });
  }

  submit(e) {
    e.preventDefault();
    let {new_status} = this.state;
    let {onNewStatus} = this.props;
    onNewStatus(new_status);
    this.setState({show_form: false});
  }

  change(e) {
    let {new_status} = this.state;
    let {name, value} = e.target;
    new_status[name] = value;
    this.setState({new_status});
  }

  newStatus() {
    let {status_records} = this.props;
    let new_status = {type: '', notes: ''};

    if (status_records.length > 0) {
      let latest_status = status_records[status_records.length - 1]
      new_status.type = latest_status.type;
    }

    this.setState({show_form: true, new_status});
  }

  closeForm(e) {
    e.preventDefault();
    this.setState({show_form: false});
  }

  renderButton() {
    return (
      <button className="btn btn-sm btn-default" onClick={e => this.newStatus()}>
        Update Status
      </button>
    )
  }

  renderForm() {
    let {show_form, show_history, new_status} = this.state;
    if (!show_form) return this.renderButton();
    let type_options = this.types();

    return (
      <div className="status-holder">
        <form onSubmit={e => this.submit(e)}>
          <div className="form-group">
            <label>Status</label>
            <select className="form-control" name="type" value={new_status.type} onChange={e => this.change(e)}>
              {Object.keys(type_options).map(k => {
                return <option key={k} value={k}>{type_options[k]}</option>
              })}
            </select>
          </div>

          <div className="form-group">
            <label>Notes</label>
            <textarea className="form-control" rows="3" name="notes" value={new_status.notes} onChange={e => this.change(e)}></textarea>
          </div>
          <button type="submit" className="btn btn-sm btn-primary">Add Status</button>
          <button className="btn btn-sm btn-link" onClick={e => this.closeForm(e)}>Cancel</button>
        </form>
      </div>
    );
  }


  renderContent() {
    if (this.state.show_history) return null;

    let {status_records} = this.props;
    if (status_records.length === 0) {
      return <div><p><b>Unknown</b></p></div>;
    }

    let current = status_records[status_records.length - 1];
    return this.renderStatus(current);
  }

  renderStatus(status) {
    let {admins} = this.state;

    let date = moment(status.created_at).format('LLL');
    let user = admins.find(a => a.id === status.admin_id)
    let user_name = user ? user.name : "Unknown"
    let status_humanized = this.types()[status.type] || titleize(status.type);
    return (
      <div>
        <h2>{status_humanized}</h2>
        <p className="message-note">{status.notes}</p>
        <p className="message-author">Added by {user_name} on {date}</p>
      </div>
    )
  }


  renderHistory() {
    let {status_records} = this.props;
    if (status_records.length === 0) return null;

    if (!this.state.show_history) {
      return <button className="btn btn-sm btn-link" onClick={e => this.setState({show_history: true})}>Show History ({status_records.length})</button>
    }

    let history_records = [];
    for (let i=status_records.length -1; i >= 0; i-- ) {
      history_records.push(
        <div key={i}>
          {this.renderStatus(status_records[i])}
        </div>
      )
    }

    return (
      <div className="status-holder">
        {history_records}
        <button className="btn btn-sm btn-link pull-right" onClick={e => this.setState({show_history: false})}>Close History</button>
        <div className="clearfix"></div>
      </div>
    )
  }

  render() {
    return (
      <div id={this.id()}>
        {this.renderContent()}
        {this.renderForm()}
        {this.renderHistory()}
      </div>
    )
  }
}

class RationaleStatus extends AbstractStatus {
  id() { return 'rationale_status'; }
  types() {
    return RationaleStatuses;
  }

  label() {
    return "Rationale Status";
  }
}


class Locations extends Component {
  constructor(props) {
    super(props);
    let {trial_locations, location_ids, patient} = props;
    attachDistances(patient, trial_locations);

    let filter = ''
    let available_locations = filterLocations(filter, props);
    let sorted_location_ids = sortLocationIDS(props);
    let suggested_locations = getClosestLocations(patient, trial_locations);

    this.state = {filter, available_locations, sorted_location_ids, suggested_locations};
  }

  static getDerivedStateFromProps(props, state) {
    let {filter} = state;
    state.available_locations = filterLocations(filter, props);
    state.sorted_location_ids = sortLocationIDS(props);
    return state;
  }

  changeFilter(e) {
    let filter = e.target.value;
    let available_locations = filterLocations(filter, this.props);
    this.setState({available_locations, filter});
  }

  addLocation(location_id) {
    let {onChange, location_ids} = this.props;
    location_ids.push(location_id);
    onChange('trial_location_ids', location_ids);
    this.closeForm();
  }

  changePrimaryLocation(primary_location_id) {
    let {onChange} = this.props;
    onChange('primary_trial_location_id', primary_location_id)
  }

  removeLocation(location_id) {
    let {onChange, location_ids, primary_location_id} = this.props;
    let index = location_ids.findIndex(id => id === location_id);
    location_ids.splice(index, 1);
    onChange('trial_location_ids', location_ids);
    if (primary_location_id === location_id) {
      this.changePrimaryLocation(null);
    }
  }

  showForm() {
    this.setState({show_form: true});
  }

  closeForm() {
    this.setState({show_form: false});
  }

  renderForm() {
    let {available_locations, filter} = this.state;

    return (
      <div className="well" >
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter the location name" value={filter} onChange={e => this.changeFilter(e)}/>
        </div>
        <div id="available_locations" className="available_list">
          {available_locations.map(l => {
            let label = getLocationLabel(l);
            return (
              <div key={l.id}>
                <button className="btn btn-link" onClick={e => this.addLocation(l.id)}>{label}</button>
              </div>
            )
          })}
        </div>
        <button className="btn btn-sm btn-default" onClick={e => this.closeForm()}>Cancel</button>
      </div>
    )
  }

  renderLocation(location_id) {
    let {primary_location_id} = this.props;
    let location = this.props.trial_locations.find(l => l.id === location_id);
    let verified, verified_status,  source_of_verification, verified_notes, primary_location;

    if (location.source_of_verification) {
      let option = TrialLocationSourceOfVerificationOptions.find(o => o.id === location.source_of_verification);
      source_of_verification = option.name;
    }

    if (location.slot_availability) {
      let option = TrialLocationAvailabilityOptions.find(o => o.id === location.slot_availability)
      verified_status = <span className={location.slot_availability}>{option.name}</span>;
    }

    if (location.verified_notes) {
      verified_notes = <p>{location.verified_notes}</p>
    }

    if (location.verified) {
      verified = (
        <>
        <p className="verification_status">Verification ({source_of_verification}): {verified_status}</p>
          {verified_notes}
          <p className="subtle">{location.verified_by_name} on {moment(location.verified_at).format('LL')}</p>
        </>
      )
    } else {
      verified = <b className="red">Not Verified</b>
    }

    if (primary_location_id === location.id) {
      primary_location = <button className="choose_referring_btn choose_referring_btn_active" onClick={e => this.changePrimaryLocation(null) }>Referring</button>
    } else {
      primary_location = <button className="choose_referring_btn"onClick={e => this.changePrimaryLocation(location_id) }>Make Referring</button>
    }

    let label = getLocationLabel(location);
    return (
      <div key={location.id} className="relevant-listing">
        <div>
          <p><a href={trial_location_path(location)}>{label}</a></p>
          <div className="relevant-listing-action">
            {primary_location}
            <p><strong>{location.status_humanized}</strong></p>
          </div>
          {verified}
        </div>


        <button className="btn btn-default btn-sm" onClick={e => this.removeLocation(location.id)}><i className="fa fa-times-circle"></i></button>
      </div>
    )
  }

  renderSuggestedLocation() {
    let {sorted_location_ids, suggested_locations} = this.state;
    if (sorted_location_ids && sorted_location_ids.length > 0) return null;
    if (suggested_locations.length === 0) return null;

    return (
      <div className="suggestion-locations">
        <h3>Suggested Locations:</h3>
        <ul>
        {suggested_locations.map(location => {
          let label = getLocationLabel(location);
          return (
            <li key={location.id}>
              <a onClick={e => this.addLocation(location.id)}>{label}</a>
            </li>
          );
        })}
      </ul>
      </div>
    )
  }

  renderLocations() {
    let {sorted_location_ids} = this.state;
    return (
      <div>
        <button className="btn btn-sm btn-default" onClick={e => this.showForm()}>Add Location</button>
        {this.renderSuggestedLocation()}
        {sorted_location_ids.map(location_id => {
          return this.renderLocation(location_id);
        })}
      </div>
    );

  }

  render() {
    let {show_form} = this.state;
    if (show_form) {
      return this.renderForm();
    } else {
      return this.renderLocations();
    }
  }
}


class GroupPicker extends Component {
  change(e) {
    let {onChange} = this.props;
    let {value} = e.target;
    onChange(value);
  }

  render() {
    let {groups, group_id} = this.props;
    return (
      <div className="form-group">
        <select className="form-control" placeholder="Select a group" value={group_id || ''} onChange={e => this.change(e)} >
          <option value={''}>No selected group</option>
          {groups.map(g => <option key={g.id} value={g.id}>{g.name}</option>)}
        </select>
      </div>
    )
  }
}


class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {candidates: [], open: false, loading: false};
  }

  changePrimaryPerson(primary_person_id) {
    let {onChange} = this.props;
    onChange('primary_person_id', primary_person_id)
  }

  getCandidates() {
    this.setState({loading: true});
    let {trial_id, patient_id} = this.props;
    let url = "/admin/patients/" + patient_id + "/trials/" + trial_id + "/people_candidate";
    request('GET', url).then(candidates => {
      this.setState({candidates, loading: false});
    });
  }

  open() {
    this.setState({open: true});
    this.getCandidates();
  }

  close() {
    this.setState({open: false});
  }

  addPerson(person) {
    let {onAddPerson} = this.props;
    onAddPerson(person);
    this.close();
  }

  renderForm() {
    let {person_ids} = this.props;
    let {candidates, loading} = this.state;
    let persons = candidates.filter(c => person_ids.findIndex(id => id === c.id) === -1)
    let content;
    if (loading) {
      content = <i id="loading" className="glyphicon glyphicon-refresh glyphicon-spin" style={{fontSize: 80}}/>
    }
    else if (persons.length === 0) {
      content = <span>No more contacts available</span>
    }
    return (
      <div className="well">
        {content}
        <div className="available_list">
          {persons.map(person => {
            let roles;
            if (person.roles_humanized.length > 0) roles = person.roles_humanized
            let cancer_center_el;
            if (person.cancer_centers && person.cancer_centers.length > 0) {
              cancer_center_el = person.cancer_centers.map(c => c.name).join(', ');
            }

            let label = person.name;
            if (person.titles) {
              label += ', ' + person.titles;
            }
            return (
              <p key={person.id}>
                <button className="add-relevant-contact-btn" onClick={e => this.addPerson(person)}>
                  <span className="relevant-person-name">{label}</span>
                  <span className="relevant-person-roles">{roles}</span>
                  <span className="relevant-person-center">{cancer_center_el}</span>
                </button>
              </p>
            )
          })}
        </div>

        <button className="btn btn-default btn-sm" onClick={e => this.close()}>Cancel</button>
      </div>
    );
  }

  renderPeople() {
    let {person_ids, people} = this.props;
    return (
      <div>
        <button className="btn btn-default btn-sm" onClick={e => this.open()}>Add Contact</button>
        {person_ids.map(person_id => {
          let person = people.find(p => p.id === person_id);
          return this.renderPerson(person);
        })}
      </div>
    );
  }


  renderPerson(person) {
    if (!person) return null;
    let {primary_person_id, onDeletePerson} = this.props;
    let role_el = person.roles_humanized ? <div>{person.roles_humanized}</div> : null;
    let email_el = person.email ? <div>{person.email}</div> : null;
    let phones = [];
    if (person.phone) phones.push(person.phone);
    if (person.cell_phone) phones.push(person.cell_phone);
    let phones_el = phones.length > 0 ? <div>{phones.join(', ')}</div> : null;
    let cancer_center_el;
    if (person.cancer_centers && person.cancer_centers.length > 0) {
      cancer_center_el = person.cancer_centers.map(c => c.name).join(', ');
    }

    let primary_person;
    if (primary_person_id === person.id) {
      primary_person = <button className="choose_referring_btn choose_referring_btn_active" onClick={e => this.changePrimaryPerson(null) }>Referring</button>
    } else {
      primary_person = <button className="choose_referring_btn"onClick={e => this.changePrimaryPerson(person.id) }>Make Referring</button>
    }

    let link = "/admin/persons/" + person.id;

    let label = person.name;
    if (person.titles) {
      label += ', ' + person.titles;
    }
    return (
      <div key={person.id} className="relevant-listing">
        <div>
          <a href={link}>{label}</a>
          <div className="relevant-listing-details">
            {primary_person}
            {role_el}
            {email_el}
            {phones_el}
            {cancer_center_el}
          </div>
        </div>
        <button className="btn btn-default btn-sm" onClick={e => onDeletePerson(person.id)}><i className="fa fa-times-circle"></i></button>
      </div>
    )
  }

  render() {
    let {open} = this.state;
    return (
      <div id="contacts">
        {open ? this.renderForm() : this.renderPeople()}
      </div>
    );
  }
}

function trial_location_path(location) {
  return "/admin/trials/" + location.trial_id + "/locations/" + location.id + "/edit";
}

/** Filter out locations that are already selected */
function filterLocations(filter, props) {
  let {trial_locations, location_ids} = props;

  let available_locations = trial_locations.filter(l => location_ids.findIndex(id => id === l.id) === -1)

  let re = new RegExp(filter, "i");
  available_locations = available_locations.filter(l => re.test(getLocationLabel(l)));

  return available_locations;
}

function getCenterLabel(cancer_center) {
  return cancer_center.name;
}

/** Filter out centers that are already selected */
function filterCenters(filter, props) {
  let {cancer_centers, center_ids} = props;

  let available_centers = cancer_centers.filter(l => center_ids.findIndex(id => id === l.id) === -1)

  let re = new RegExp(filter, "i");
  available_centers = available_centers.filter(l => re.test(getCenterLabel(l)));

  return available_centers;
}


function sortLocationIDS({location_ids, trial_locations}) {
  return location_ids.sort((a, b) => {
    let location_a = trial_locations.find(l => l.id === a);
    let location_b = trial_locations.find(l => l.id === b);
    let label_a = getLocationLabel(location_a).toUpperCase();
    let label_b = getLocationLabel(location_b).toUpperCase();

    if (location_a.country !== 'US' && location_b.country === 'US') return 1;
    if (location_a.country === 'US' && location_b.country !== 'US') return -1;

    if (label_a > label_b) return 1;
    else if (label_b > label_a) return  -1;
    return 0;
  });
}


function attachDistances(patient, trial_locations) {
  patient._geolocated_locations = patient.locations.filter(l => l.lat && l.lon);
  if (patient._geolocated_locations.length === 0) return;

  for (let location of trial_locations) {
    for (let center of location.cancer_centers) {
      center._distances = {};
      if (center.lat && center.lon) {
        for (let patient_location of patient._geolocated_locations) {
          center._distances[patient_location.id] = calculateDistance(patient_location, center);
        }
      }

      center._min_distances = {}
      for (let patient_location of patient._geolocated_locations) {
        center._min_distances[patient_location.id] = Math.min(center._distances[patient_location.id])
      }
    }

    location._min_distances = {}
    for (let patient_location of patient._geolocated_locations) {
      let center_distances = location.cancer_centers.map(c => c._min_distances[patient_location.id])
      location._min_distances[patient_location.id] = Math.min(center_distances)
    }
  }
}

function getClosestLocations(patient, trial_locations) {

  let locations = patient._geolocated_locations.map(patient_location => {
    let min = Infinity;
    let filtered_location = undefined;

    for (let location of trial_locations) {
      let distance = location._min_distances[patient_location.id];
      if (distance && distance < min) {
        min = distance;
        filtered_location = location;
      }
    }

    return filtered_location;
  });

  return locations.filter(l => l != undefined);
}


class CancerCenters extends Component {
  constructor(props) {
    super(props);

    let filter = '';
    let centers_filtered = filterCenters(filter, props);
    this.state = {filter, centers_filtered};
  }

  changeFilter(e) {
    let filter = e.target.value;
    let centers_filtered = filterCenters(filter, this.props);
    this.setState({centers_filtered, filter});
  }

  addCancerCenter(cancer_center_id) {
    let {onChange, center_ids} = this.props;
    center_ids.push(cancer_center_id);
    onChange('cancer_center_ids', center_ids);
    this.closeForm();
  }

  changePrimaryCancerCenter(primary_cancer_center_id) {
    let {onChange} = this.props;
    onChange('primary_cancer_center_id', primary_cancer_center_id)
  }

  removeCancerCenter(cancer_center_id) {
    let {onChange, center_ids, primary_cancer_center_id} = this.props;
    let index = center_ids.findIndex(id => id === cancer_center_id);
    center_ids.splice(index, 1);
    onChange('cancer_center_ids', center_ids);
    if (primary_cancer_center_id === cancer_center_id) {
      this.changePrimaryCancerCenter(null);
    }
  }

  showForm() {
    this.setState({show_form: true});
  }

  closeForm() {
    this.setState({show_form: false});
  }

  renderForm() {
    let {filter, centers_filtered} = this.state;

    return (
      <div className="well" >
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Enter the location name" value={filter} onChange={e => this.changeFilter(e)}/>
        </div>
        <div id="available_locations" className="available_list">
          {centers_filtered.map(center => {
            let label = getCenterLabel(center);
            return (
              <div key={center.id}>
                <button className="btn btn-link" onClick={e => this.addCancerCenter(center.id)}>{label}</button>
              </div>
            )
          })}
        </div>
        <button className="btn btn-sm btn-default" onClick={e => this.closeForm()}>Cancel</button>
      </div>
    )
  }

  renderCancerCenter(cancer_center_id) {
    let {primary_cancer_center_id} = this.props;
    let center = this.props.cancer_centers.find(l => l.id === cancer_center_id);

    let primary_cancer_center;
    if (primary_cancer_center_id === center.id) {
      primary_cancer_center = <button className="choose_referring_btn choose_referring_btn_active" onClick={e => this.changePrimaryCancerCenter(null) }>Referring</button>
    } else {
      primary_cancer_center = <button className="choose_referring_btn"onClick={e => this.changePrimaryCancerCenter(cancer_center_id) }>Make Referring</button>
    }

    let url = '/admin/cancer_centers/' + cancer_center_id;
    let label = getCenterLabel(center);
    return (
      <div key={center.id} className="relevant-listing">
        <div>
          <p><a href={url}>{label}</a></p>
          <div className="relevant-listing-action">
            {primary_cancer_center}
          </div>

        </div>


        <button className="btn btn-default btn-sm" onClick={e => this.removeCancerCenter(cancer_center_id)}><i className="fa fa-times-circle"></i></button>
      </div>
    )
  }


  renderCancerCenters() {
    let {center_ids} = this.props;
    return (
      <div>
        <button className="btn btn-sm btn-default" onClick={e => this.showForm()}>Add Cancer Center</button>
        {center_ids.map(cancer_center_id => {
          return this.renderCancerCenter(cancer_center_id);
        })}
      </div>
    );

  }

  render() {
    let {show_form} = this.state;
    if (show_form) {
      return this.renderForm();
    } else {
      return this.renderCancerCenters();
    }
  }
}
