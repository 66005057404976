import React, {Component} from 'react';
import request from '../request';

export default class PatientsActiveThisWeek extends Component {
  constructor(props) {
    super(props);
    let {patients} = props;
    this.state = {patients};
    this.toggleActiveThisWeek = this.toggleActiveThisWeek.bind(this);
    this.toggleActiveThisMonth = this.toggleActiveThisMonth.bind(this);
  }

  active_this_week(patient) {
    let url = `/admin/patients/${patient.id}/active_this_week`;
    request('PUT', url).then(patient => {
      let {patients} = this.state;
      let index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      this.setState({patients});
    })
  }

  active_this_month(patient) {
    let url = `/admin/patients/${patient.id}/active_this_month`;
    request('PUT', url).then(patient => {
      let {patients} = this.state;
      let index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      this.setState({patients});
    })
  }

  disable(patient) {
    let url = `/admin/patients/${patient.id}/inactive`;
    request('PUT', url).then(patient => {
      let {patients} = this.state;
      let index = patients.findIndex(p => p.id === patient.id);
      patients[index] = patient;
      this.setState({patients});
    })
  }

  toggleActiveThisWeek(){
    let show_not_active = !this.state.show_not_active;
    this.setState({show_not_active});
  }

  toggleActiveThisMonth(){
    let show_active_this_month = !this.state.show_active_this_month;
    this.setState({show_active_this_month});
  }

  renderActiveThisWeek() {
    let {patients} = this.state;
    let active = patients.filter(p => p.active_status === 'w');
    if (!active || active.length === 0) return null;

    // Sort patients by name
    active = active.sort(compareByName);

    return (
      <table className="table table-condensed table-striped">
        <tbody>
          {active.map(patient => {
            let link = '/admin/patients/' + patient.id;
            return (
              <tr key={patient.id}>
                <td><a href={link}>{patient.name}</a> <span className="subtle">{patient.cancer_type}</span></td>
                <td>
                  <div className="table_mark_controls">
                    <span>Mark:</span>
                    <div className="btn-group">
                      <button className="btn btn-xs btn-default" onClick={e => this.active_this_month(patient)}>This Month</button>
                      <button className="btn btn-xs btn-default" onClick={e => this.disable(patient)}>Not Active</button>
                    </div>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }


  renderActiveThisMonth() {
    let {show_active_this_month} = this.state;
    let btnLabel = show_active_this_month ? 'Hide' : 'Show';

    return (
      <div className="inactive-holder">
        <button className="btn btn-default btn-sm" onClick={this.toggleActiveThisMonth}>{btnLabel} Active This Month</button>
        {this.renderActiveThisMonthList()}
      </div>
    );
  }

  renderActiveThisMonthList() {
    let {patients, show_active_this_month} = this.state;
    if(!show_active_this_month) return null;

    let active = patients.filter(p => p.active_status === 'm');
    if (!active || active.length === 0) return null;

    // Sort patients by name
    active = active.sort(compareByName);

    return (
      <>
        <h2 className="admin_section_header section_header_spaced">ACTIVE THS MONTH</h2>
        <table className="table table-condensed table-striped">
          <tbody>
            {active.map(patient => {
              let link = '/admin/patients/' + patient.id;
              return (
                <tr key={patient.id}>
                  <td><a href={link}>{patient.name}</a> <span className="subtle">{patient.cancer_type}</span></td>
                  <td>
                    <div className="table_mark_controls">
                      <span>Mark:</span>
                      <div className="btn-group">
                        <button className="btn btn-xs btn-default" onClick={e => this.active_this_week(patient)}>This Week</button>
                        <button className="btn btn-xs btn-default" onClick={e => this.disable(patient)}>Not Active</button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }


  renderInactive() {
    let {show_not_active} = this.state;
    let btnLabel = show_not_active ? 'Hide' : 'Show';

    return (
      <div className="inactive-holder">
        <button className="btn btn-default btn-sm" onClick={this.toggleActiveThisWeek}>{btnLabel} Not Active</button>
        {this.renderInactiveList()}
      </div>
    );
  }

  renderInactiveList() {
    let {patients, show_not_active} = this.state;
    if (!show_not_active) return null;

    let inactive = patients.filter(p => p.active_status === null);
    if (!inactive || inactive.length === 0) return null;

    // Sort patients by name
    inactive = inactive.sort(compareByName);

    return (
      <>
        <h2 className="admin_section_header section_header_spaced">NOT ACTIVE</h2>
        <table className="table table-condensed table-striped table-inactive-patients">
          <tbody>
            {inactive.map(patient => {
              let link = '/admin/patients/' + patient.id;
              return (
                <tr key={patient.id}>
                  <td><a href={link}>{patient.name}</a>  <span className="subtle">{patient.cancer_type}</span></td>
                  <td>
                    <div className="table_mark_controls">
                      <span>Mark:</span>
                      <div className="btn-group">
                        <button className="btn btn-xs btn-default"  onClick={e => this.active_this_week(patient)}>This Week</button>
                        <button className="btn btn-xs btn-default" onClick={e => this.active_this_month(patient)}>This Month</button>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  render() {
    return (
      <div>
        <h2 className="admin_section_header">Active This Week</h2>
        {this.renderActiveThisWeek()}
        {this.renderActiveThisMonth()}
        {this.renderInactive()}
      </div>
    );
  }
}


function compareByName( a, b ) {
  return ('' + a.name).localeCompare(b.name);
}
